import React, { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment-timezone';
import { formatCurrency } from '../utils/formatter';

const CbmRateCountsSummary = ({ 
    selectedYears, 
    selectedMonths, 
    reportList, 
    overallTotalBillableAmount, 
    groupByAppliedRate 
}) => {
    return (
        <>
            {selectedYears.map((year, yearIndex) => (
                <Fragment key={yearIndex}>
                    <Table className="main-tbl-summary mt-5">
                        {selectedMonths.map((monthObj, index) => {
                            const { hasResult, grouped } = groupByAppliedRate(reportList, year, monthObj.month);
                            return (
                                <Fragment key={index}>
                                    <thead>
                                        <tr>
                                            <th className="border-bottom month-indicator">
                                                <h5>{monthObj.label} {year}</h5>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-bottom">
                                                {hasResult ? (
                                                    <Table hover className="billing-table-list align-items-center">
                                                        <thead>
                                                            <tr>
                                                                <th className="border-bottom">Applied Rate</th>
                                                                <th className="border-bottom">Total Count</th>
                                                                <th className="border-bottom">Total CBM</th>
                                                                <th className="border-bottom">Total Billed Amount</th>
                                                                <th className="border-bottom">Total Percentage</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {grouped.map((entry, index) => {
                                                                const filteredItems = reportList.filter(item =>
                                                                    moment(item.createdDate).year() === year &&
                                                                    moment(item.createdDate).month() + 1 === monthObj.month &&
                                                                    item.appliedRate === entry.appliedRate
                                                                );

                                                                const totalCBM = filteredItems.reduce((sum, item) => sum + parseFloat(item.totalCBM), 0).toFixed(2);
                                                                const totalBilledAmount = filteredItems.reduce((sum, item) => sum + parseFloat(item.grossAmount), 0).toFixed(2);
                                                                const result = (parseFloat(totalBilledAmount) / parseFloat(overallTotalBillableAmount)) * 100;
                                                                const percentage = result.toFixed(2);

                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{formatCurrency(parseFloat(entry.appliedRate))}</td>
                                                                        <td>{entry.count}</td>
                                                                        <td>{totalCBM}</td>
                                                                        <td>{formatCurrency(parseFloat(totalBilledAmount))}</td>
                                                                        <td>{percentage}%</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                ) : (
                                                    <div>*** No Records Found ***</div>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Fragment>
                            );
                        })}
                    </Table>
                </Fragment>
            ))}
        </>
    );
};

export default CbmRateCountsSummary;
