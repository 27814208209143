import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faCheck, faEdit, faEllipsisH, faEye } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Table, Row, Col, ListGroup, Image, ButtonGroup, Dropdown } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import ProductDetail from "./productdetail";
import ProductForm from "./productform";
import { useSelector } from "react-redux";
import PortalPagination from "../components/PortalPagination";
import { confirmArchiveDialog, confirmArchiveDialogEmptyCallback } from "../components/Alerts";
import ProductArchiveReason from "./productarchivereason";
import productApi from '../services/product-service';
import { formatCurrency, truncateDescription } from "../utils/formatter";
import { defaultTruncateLength, PRODUCT_MANAGER_DELETE_PRODUCT, PRODUCT_MANAGER_UPDATE_PRODUCT } from "../utils/constants";
import { hasPermission } from "../auth/UserPermissionTypes";

const ProductDatatable = (props) => {
    const { refreshList, selectedPage, pagingEvent } = props;
    const { productList, productCategories } = props;
    const totalRecords = useSelector((state) => state.productState.count) || 0;
    const loading = useSelector((state) => state.blanket.showAdminLoader);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [itemActionType, setItemActionType] = useState('View');
    const [showArchiveReason, setShowArchiveReason] = useState(false);
    const [productToArchive, setProductToArchive] = useState(null);
    const { activateProduct } = productApi;

    const getProductPhoto = (product) => {
        const primaryResource = product.resources.find(resource => resource.isPrimary);
        return primaryResource ? primaryResource.thumbnail || primaryResource.resourceUrl : process.env.REACT_APP_NO_IMAGE;
    }

    const handleCancelEdit = () => {
        setSelectedProduct(null);
        setItemActionType('View');
    }

    const handleViewProduct = (product) => {
        setSelectedProduct(product);
        setItemActionType('View');
    }

    const handleEditProduct = (product) => {
        setSelectedProduct(product);
        setItemActionType('Edit');
    }

    const handleArchiveProduct = async (id) => {
        let text = 'Do you want to archive this product?';
        let confirmBtnText = 'Yes, archive it!';

        var statusConfirm = await confirmArchiveDialogEmptyCallback(text, confirmBtnText);
        if (statusConfirm) {
            setShowArchiveReason(true);
            setProductToArchive(id);
        }
    };

    const handleCancelArchive = () => {
        setShowArchiveReason(false);
        setProductToArchive(null);
    }

    const handleActivateProduct = async (id) => {
        let text = 'Do you want to activate this product?';
        let confirmBtnText = 'Yes, activate it!';
        let callbackTitle = 'Activate!';
        let callbackText = 'Product has been Activated!';

        var statusConfirm = await confirmArchiveDialog(text, confirmBtnText, callbackTitle, callbackText);
        if (statusConfirm) {
            let productData = new FormData();
            productData.append('productId', id);
            activateProduct(productData).then(() => {
                refreshList();
            });
        }
    }

    return (
        <>
            {showArchiveReason && (
                <ProductArchiveReason productId={productToArchive} cancelEvent={handleCancelArchive} refreshList={refreshList} />
            )}
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="pt-0">
                    <Table hover className="billing-table-list align-items-center">
                        <thead>
                            <tr>
                                <th className="border-bottom">Product Name</th>
                                <th className="border-bottom">Model</th>
                                <th className="border-bottom">Price</th>
                                <th className="border-bottom">Mark Up (%)</th>
                                <th className="border-bottom">Color</th>
                                <th className="border-bottom">Size</th>
                                <th className="border-bottom">Description</th>
                                <th className="border-bottom">Archive Reason</th>
                                <th className="border-bottom"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {productList.map((item, index) => (
                                <Fragment key={index}>
                                    <tr>
                                        <td>
                                            {loading && selectedProduct == null ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                <ListGroup className="list-group-flush list my--3">
                                                    <ListGroup.Item className="px-0">
                                                        <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                                <a href="#!" className="user-avatar" onClick={() => handleViewProduct(item)}>
                                                                    <Image src={getProductPhoto(item)} className="rounded-circle" />
                                                                </a>
                                                            </Col>
                                                            <Col className="ms--2">
                                                                <h4 className="h6 mb-0">
                                                                    <a href="#!" onClick={() => handleViewProduct(item)}>{item.name}</a>
                                                                </h4>
                                                                {item.isActive ? (
                                                                    <>
                                                                        <span className={`text-success`}>● </span>
                                                                        <small>Active</small>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className={`text-danger`}>● </span>
                                                                        <small>In-Active</small>
                                                                    </>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            )}
                                        </td>
                                        <td>
                                            <span className="fw-normal">
                                                {loading && selectedProduct == null ? (
                                                    <Skeleton wrapper={SkeletonWrapper} />
                                                ) : (
                                                    item.model
                                                )}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="fw-normal">
                                                {loading && selectedProduct == null ? (
                                                    <Skeleton wrapper={SkeletonWrapper} />
                                                ) : (
                                                    formatCurrency(parseFloat(item.price) || 0.00)
                                                )}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="fw-normal">
                                                {loading && selectedProduct == null ? (
                                                    <Skeleton wrapper={SkeletonWrapper} />
                                                ) : (
                                                    `${item.markUpPrice}%`
                                                )}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="fw-normal">
                                                {loading && selectedProduct == null ? (
                                                    <Skeleton wrapper={SkeletonWrapper} />
                                                ) : (
                                                    item.color
                                                )}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="fw-normal">
                                                {loading && selectedProduct == null ? (
                                                    <Skeleton wrapper={SkeletonWrapper} />
                                                ) : (
                                                    item.size
                                                )}
                                            </span>
                                        </td>
                                        <td title={item?.description?.length > defaultTruncateLength ? item?.description : ''}>
                                            <span className="fw-normal">
                                                {loading && selectedProduct == null ? (
                                                    <Skeleton wrapper={SkeletonWrapper} />
                                                ) : (
                                                    item?.description ? truncateDescription(item?.description, defaultTruncateLength) : ''
                                                )}
                                            </span>
                                        </td>
                                        <td title={item?.archiveReason?.length > defaultTruncateLength ? item?.archiveReason : ''}>
                                            <span className="fw-normal">
                                                {loading && selectedProduct == null ? (
                                                    <Skeleton wrapper={SkeletonWrapper} />
                                                ) : (
                                                    item?.archiveReason ? truncateDescription(item?.archiveReason, defaultTruncateLength) : ''
                                                )}
                                            </span>
                                        </td>
                                        <td>
                                            <Dropdown as={ButtonGroup}>
                                                <Dropdown.Toggle as={Button} split={true} variant="link" className="text-dark m-0 p-0">
                                                    <span className="icon icon-sm">
                                                        <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="more-left-dropdown-menu">
                                                    <Dropdown.Item onClick={() => handleViewProduct(item)}>
                                                        <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                                    </Dropdown.Item>
                                                    {hasPermission(PRODUCT_MANAGER_UPDATE_PRODUCT) && (
                                                        <Dropdown.Item onClick={() => handleEditProduct(item)}>
                                                            <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                                                        </Dropdown.Item>
                                                    )}
                                                    {item.isActive ? (
                                                        <>
                                                            {hasPermission(PRODUCT_MANAGER_DELETE_PRODUCT) && (
                                                                <Dropdown.Item className="text-danger" onClick={() => handleArchiveProduct(item._id)}>
                                                                    <FontAwesomeIcon icon={faArchive} className="me-2" /> Archive
                                                                </Dropdown.Item>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {hasPermission(PRODUCT_MANAGER_DELETE_PRODUCT) && (
                                                                <Dropdown.Item className="text-success" onClick={() => handleActivateProduct(item._id)}>
                                                                    <FontAwesomeIcon icon={faCheck} className="me-2" /> Activate
                                                                </Dropdown.Item>
                                                            )}
                                                        </>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                    {selectedProduct && (
                                        <tr>
                                            <td colSpan={9}>
                                                {item._id === selectedProduct._id && itemActionType === 'View' && (
                                                    <ProductDetail product={selectedProduct} cancelEdit={handleCancelEdit} />
                                                )}
                                                {item._id === selectedProduct._id && itemActionType === 'Edit' && (
                                                    <ProductForm 
                                                        productCategories={productCategories} 
                                                        product={selectedProduct} 
                                                        cancelEdit={handleCancelEdit}
                                                        refreshList={refreshList}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            ))}
                        </tbody>
                    </Table>
                    {!loading && (
                        <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={pagingEvent} />
                    )}
                </Card.Body>
            </Card>
        </>
    );
}

export default ProductDatatable;