import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPortalPage } from '../actions/blanket';
import { Card, Button, Table, Row, Col, Form, InputGroup, ButtonGroup } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import reportApi from '../services/report-service';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import './styles.scss';
import { formatCurrency } from "../utils/formatter";
import { generatePdfReportAndDownload } from '../pdfGenerator';
import FclTransactionsPreview from "./flctransactionspreview";
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { addCompanyHeader } from "../excelreportgenerator";

const FclTransactions = () => {

    const defaultStartDate = moment().startOf('month').toDate();
    const defaultEndDate = moment().endOf('month').toDate();
    
    const dispatch = useDispatch();
    const { getFclTransactions } = reportApi;
    const loading = useSelector((state) => state.blanket.showAdminLoader);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [reportList, setReportList] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [overallTotalRevenue, setOverallTotalRevenue] = useState(0);
    const [overallTotalCOS, setOverallTotalCOS] = useState(0);
    const [overallTotalGrossProfit, setOverallTotalGrossProfit] = useState(0);

    const formSchema = Yup.object().shape({
        startDate: Yup.string().nullable().required('Start date is required')
            .test('start-date-test', 'Start date cannot be greater than end date', function(value) {
                const { endDate } = this.parent;
                return !value || !endDate || moment(value).isSameOrBefore(moment(endDate));
            }),
        endDate: Yup.string().nullable().required('End date is required')
            .test('end-date-test', 'End date cannot be earlier than start date', function(value) {
                const { startDate } = this.parent;
                return !value || !startDate || moment(value).isSameOrAfter(moment(startDate));
            }),
    });

    const formOptions = {
        resolver: yupResolver(formSchema),
        mode: 'all',
        defaultValues: {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        }
    };

    const { getValues, trigger, formState, setValue, reset } = useForm(formOptions);
    const { errors } = formState;

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setValue('startDate', date, { shouldValidate: true });
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setValue('endDate', date, { shouldValidate: true });
    };

    const resetFilters = () => {
        reset({
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        });
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setReportList([]);
    };

    const handleGenerateReport = async () => {
        try {
            const isValid = await trigger();
    
            if (!isValid) {
                return;
            }
    
            const { startDate, endDate } = getValues();
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : undefined;
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : undefined;
    
            const response = await getFclTransactions(formattedStartDate, formattedEndDate, 'FCL');
            const reportData = response?.data || [];
    
            // Calculate overall totals
            const totalRevenue = reportData.reduce((acc, item) => acc + (parseFloat(item.revenue) || 0), 0);
            const totalCOS = reportData.reduce((acc, item) => acc + (parseFloat(item.cos) || 0), 0);
            const totalGrossProfit = reportData.reduce((acc, item) => acc + (parseFloat(item.grossProfit) || 0), 0);
    
            setOverallTotalRevenue(totalRevenue.toFixed(2));
            setOverallTotalCOS(totalCOS.toFixed(2));
            setOverallTotalGrossProfit(totalGrossProfit.toFixed(2));
            setReportList(reportData);
        } catch (error) {
            console.log("Error while generating FCL transactions report: ", error);
        }
    };
    

    const handleDownloadPdf = async () => {
        try {
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : 'start';
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : 'end';
    
            const fileName = `fcl_transactions_report_${formattedStartDate}_${formattedEndDate}.pdf`;
            const pdfContent = document.getElementById('element-to-print-fcl-transactions-report').innerHTML;
    
            await generatePdfReportAndDownload(pdfContent, fileName);

            setDownloading(false);
    
        } catch (error) {
            console.error('Error in downloading PDF:', error);
        }
    };

    const handleDownloadExcel = async () => {
        try {
            setDownloading(true);
    
            const { startDate, endDate } = getValues();
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : undefined;
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : undefined;
    
            const response = await getFclTransactions(formattedStartDate, formattedEndDate, 'FCL');
            const reportData = response?.data || [];
    
            // Create a new workbook and worksheet
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('FCL Transactions');
    
            // Add company header, title, and date range to the worksheet
            await addCompanyHeader(worksheet, 'FCL Transactions Report', formattedStartDate, formattedEndDate);

            worksheet.getRow(12).height = 20;
            worksheet.getRow(13).height = 20;
    
            // Add column headers starting from row 14
            const headerRow = worksheet.addRow([
                'Quotation Number',
                'Client Name',
                'Revenue',
                'COS',
                'Gross Profit',
                'GP%',
                'Agent Name',
                'Approved Date',
            ]);
    
            // Style the column headers: make them bold and add background color
            headerRow.eachCell((cell) => {
                cell.font = { bold: true }; // Make text bold
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFEAEAEA' }, // Set background color to #eee
                };
                cell.alignment = { horizontal: 'center' }; // Center-align text
            });
    
            // Set column widths for better visibility
            worksheet.columns = [
                { key: 'quotationNumber', width: 20 },
                { key: 'clientName', width: 30 },
                { key: 'revenue', width: 20 },
                { key: 'cos', width: 20 },
                { key: 'grossProfit', width: 20 },
                { key: 'gpPercentage', width: 20 },
                { key: 'agentName', width: 25 },
                { key: 'approvedDate', width: 20 },
            ];
    
            // Add data rows
            reportData.forEach((item) => {
                worksheet.addRow([
                    item.quotationNumber,
                    item.clientName,
                    formatCurrency(parseFloat(item.revenue)) || 0.00,
                    formatCurrency(parseFloat(item.cos)) || 0.00,
                    formatCurrency(parseFloat(item.grossProfit)) || 0.00,
                    `${item.grossProfitPercentage}%`,
                    item.agentName,
                    moment(item.approvedDate).format('YYYY-MM-DD'),
                ]);
            });
    
            // Add the totals at the bottom of the sheet (in row after the last data row)
            const lastRow = worksheet.lastRow.number + 1;
    
            // Add Overall Total Revenue in Column A
            worksheet.getCell(`A${lastRow}`).value = 'Overall Total Revenue:';
            worksheet.getCell(`A${lastRow}`).font = { bold: true, size: 16 };
            worksheet.getCell(`B${lastRow}`).value = formatCurrency(parseFloat(overallTotalRevenue)) || 0.00;
    
            // Add Overall Total COS in Column D
            worksheet.getCell(`D${lastRow}`).value = 'Overall Total COS:';
            worksheet.getCell(`D${lastRow}`).font = { bold: true, size: 16 };
            worksheet.getCell(`E${lastRow}`).value = formatCurrency(parseFloat(overallTotalCOS)) || 0.00;
    
            // Add Overall Total Gross Profit in Column G
            worksheet.getCell(`G${lastRow}`).value = 'Overall Total Gross Profit:';
            worksheet.getCell(`G${lastRow}`).font = { bold: true, size: 16 };
            worksheet.getCell(`H${lastRow}`).value = formatCurrency(parseFloat(overallTotalGrossProfit)) || 0.00;
    
            // Save the Excel file
            const excelFileName = `fcl_transactions_report_${formattedStartDate}_${formattedEndDate}.xlsx`;
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer]), excelFileName);
            });
    
            setDownloading(false);
        } catch (error) {
            console.log("Error while generating Excel report: ", error);
            setDownloading(false);
        }
    };
    

    const handleDownloadReport = async () => {
        try {
            setDownloading(true);

            setTimeout(() => {
                handleDownloadPdf();
            }, 100);
        } catch (error) {
            console.log("Error while generating FCL transactions report: ", error);
        }
    };

    useEffect(() => {
        dispatch(setPortalPage('List of FCL Transactions (With Revenue, COS, Gross Profit & Percentage of GP)'));
    }, [dispatch]);

    return (
        <>
            <Card border="light" className="table-wrapper table-responsive shadow-sm card-wrapper-full-height">
                <Card.Body className="pt-0">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-4 mb-md-0">
                            <p className="mb-0">List of FCL Transactions (With Revenue, COS, Gross Profit & Percentage of GP)</p>
                        </div>
                    </div>
                    <div className="table-settings mb-2">
                        <Row className="justify-content-between align-items-center">
                            <Col md={6}>
                                <Form.Group id="start-date">
                                    <Form.Label>Start Date</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={handleStartDateChange}
                                        closeOnSelect={true}
                                        renderInput={(props, openCalendar) => (
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faCalendar} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="startDate"
                                                    value={startDate ? moment(startDate).format('MM/DD/YYYY') : ''}
                                                    placeholder="Select date"
                                                    onFocus={openCalendar}
                                                    autoComplete="off"
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    {errors.startDate?.message && (
                                        <small className="invalid-fields">{errors.startDate.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group id="end-date">
                                    <Form.Label>End Date</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={handleEndDateChange}
                                        closeOnSelect={true}
                                        renderInput={(props, openCalendar) => (
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faCalendar} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="endDate"
                                                    value={endDate ? moment(endDate).format('MM/DD/YYYY') : ''}
                                                    placeholder="Select date"
                                                    onFocus={openCalendar}
                                                    autoComplete="off"
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    {errors.endDate?.message && (
                                        <small className="invalid-fields">{errors.endDate.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="table-settings mb-5">
                        <Row>
                            <Col md={12}>
                                <Button variant="primary" onClick={() => handleGenerateReport()}>
                                    Search
                                </Button>
                                <Button className="ms-2" variant="secondary" onClick={() => resetFilters()}>
                                    Reset
                                </Button>
                                {reportList.length > 0 && (
                                    <ButtonGroup className="pull-right ms-2">
                                        <Button variant="primary" size="sm" type="button" onClick={() => handleDownloadReport()}>
                                            <FontAwesomeIcon icon={faFilePdf} /> {`${downloading ? 'Downloading...' : 'Download PDF'}`}
                                        </Button>
                                        <Button variant="secondary" size="sm" type="button" onClick={() => handleDownloadExcel()}>
                                            <FontAwesomeIcon icon={faFileExcel} /> {`${downloading ? 'Downloading...' : 'Download Excel'}`}
                                        </Button>
                                    </ButtonGroup>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <Table hover className="billing-table-list align-items-center">
                        <thead>
                            <tr>
                                <th className="border-bottom">Quotation Number</th>
                                <th className="border-bottom">Client Name</th>
                                <th className="border-bottom">Revenue</th>
                                <th className="border-bottom">COS</th>
                                <th className="border-bottom">Gross Profit</th>
                                <th className="border-bottom">GP%</th>
                                <th className="border-bottom">Agent Name</th>
                                <th className="border-bottom">Approved Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportList.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.quotationNumber
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.clientName
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                formatCurrency(parseFloat(item.revenue)) || 0.00
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                formatCurrency(parseFloat(item.cos)) || 0.00
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                formatCurrency(parseFloat(item.grossProfit)) || 0.00
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                `${item.grossProfitPercentage}%`
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.agentName
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                moment(item.approvedDate).format('YYYY-MM-DD')
                                            )}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {reportList.length > 0 && (
                        <div className="mt-4">
                            <Row>
                                <Col md={4}>
                                    <h4><strong>Overall Total Revenue:</strong> {loading ? <Skeleton /> : `${formatCurrency(parseFloat(overallTotalRevenue)) || 0.00}`}</h4>
                                </Col>
                                <Col md={4}>
                                    <h4><strong>Overall Total COS:</strong> {loading ? <Skeleton /> : `${formatCurrency(parseFloat(overallTotalCOS)) || 0.00}`}</h4>
                                </Col>
                                <Col md={4}>
                                    <h4><strong>Overall Total Gross Profit:</strong> {loading ? <Skeleton /> : `${formatCurrency(parseFloat(overallTotalGrossProfit)) || 0.00}`}</h4>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Card.Body>
            </Card>
            <div className='report-element-to-print' id='element-to-print-fcl-transactions-report'>
                <FclTransactionsPreview 
                    startDate={startDate} 
                    endDate={endDate} 
                    reportList={reportList}
                    overallTotalRevenue={overallTotalRevenue}
                    overallTotalCOS={overallTotalCOS}
                    overallTotalGrossProfit={overallTotalGrossProfit}
                />
            </div>
        </>
    );
};

export default FclTransactions;
