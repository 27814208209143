import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPortalPage } from '../actions/blanket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, Table, Row, Col, Form, InputGroup, ButtonGroup } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { faCalendar, faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import userApi from '../services/user-service';
import reportApi from '../services/report-service';
import { setSalesAgentTransactionReportCount } from '../actions/reports';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import './styles.scss';
import { generatePdfReportAndDownload } from '../pdfGenerator';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { 
    CLIENT_NO_RESPONSE, 
    defaultPageNumber, 
    EXPIRED_QUOTATION, 
    FINAL_QUOTATION, 
    PENDING_CLIENT_SIGNATURE_QUOTATION, 
    PENDING_GENERAL_MANAGER_APPROVAL, 
    PROFORMA_INVOICE, 
    SIGNED_QUOTATION 
} from "../utils/constants";
import { formatCurrency } from "../utils/formatter";
import PortalPagination from "../components/PortalPagination";
import SalesAgentTransactionsPreview from "./salesagenttransactionspreview";
import { addCompanyHeader, fetchSrtBrandImageAsBase64 } from "../excelreportgenerator";


const SalesAgentTransactions = () => {

    const transactionStatuses = [
        { value: "All", label: "All" },
        { value: PROFORMA_INVOICE, label: "Proforma Invoice" },
        { value: PENDING_GENERAL_MANAGER_APPROVAL, label: "Pending General Manager Approval" },
        { value: FINAL_QUOTATION, label: "Final Quotation" },
        { value: SIGNED_QUOTATION, label: "Signed Quotation" },
        { value: PENDING_CLIENT_SIGNATURE_QUOTATION, label: "Pending Client Signature Quotation" },
        { value: CLIENT_NO_RESPONSE, label: "Client No Response" },
        { value: EXPIRED_QUOTATION, label: "Expired Quotation" }
    ];

    const defaultStartDate = moment().startOf('month').toDate();
    const defaultEndDate = moment().endOf('month').toDate();

    const dispatch = useDispatch();
    const { getSalesAgents } = userApi;
    const { getTransactionsHandledByAgentCount, getTransactionsHandledByAgentList, getAllTransactionsHandledByAgentList } = reportApi;
    const loading = useSelector((state) => state.blanket.showAdminLoader);
    const totalRecords = useSelector((state) => state.reportState.salesAgentTransactionReportCount) || 0;
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [salesAgents, setSalesAgents] = useState([]);
    const [reportList, setReportList] = useState([]);
    const [selectedPage, setSelectedPage] = useState(defaultPageNumber);
    const [allReportList, setAllReportList] = useState([]);
    const [downloading, setDownloading] = useState(false);

    const formSchema = Yup.object().shape({
        agentId: Yup.string().required('Agent is required'),
        status: Yup.string().required('Transaction status is required'),
        startDate: Yup.string().nullable().required('Start date is required')
            .test('start-date-test', 'Start date cannot be greater than end date', function(value) {
                const { endDate } = this.parent;
                return !value || !endDate || moment(value).isSameOrBefore(moment(endDate));
            }),
        endDate: Yup.string().nullable(),
    });

    const formOptions = {
        resolver: yupResolver(formSchema),
        mode: 'all',
        defaultValues: {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        }
    };

    const { register, getValues, trigger, formState, setValue, reset } = useForm(formOptions);

    const { errors } = formState;

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setValue('startDate', date, { shouldValidate: true });
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setValue('endDate', date, { shouldValidate: true });
    };

    const resetFilters = () => {
        reset({
            agentId: '',
            status: '',
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        });
    
        // Reset the state for the filters and page number
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setSelectedPage(1);
    
        // Fetch the audit logs with no filters applied, and page number set to 1
        dispatch(setSalesAgentTransactionReportCount(0));
        setReportList([]);
        setAllReportList([]);
    };

    const handlePageEvent = (pageNumber) => {
        handleGenerateReport(pageNumber);
    };

    const handleGenerateReport = async (pageNumber = 1) => {
        try {
            // Manually trigger validation for all form fields
            const isValid = await trigger();

            if (!isValid) {
                return; // Stop if validation fails
            }

            // Get filter values from the form
            const { agentId, status } = getValues();
    
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : undefined;
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : undefined;

            // Fetch the count of reports
            const countResponse = await getTransactionsHandledByAgentCount(agentId, status, formattedStartDate, formattedEndDate);
            dispatch(setSalesAgentTransactionReportCount(countResponse.count));

            const listResponse = await getTransactionsHandledByAgentList(pageNumber, agentId, status, formattedStartDate, formattedEndDate);
            setReportList(listResponse);
        } catch (error) {
            console.log("Error while generating sales agent transactions report: ", error);
        }
    };

    const handleDownloadPdf = async () => {
        try {
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : 'start';
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : 'end';
    
            const fileName = `sales_agent_transaction_report_${formattedStartDate}_${formattedEndDate}.pdf`;
    
            const pdfContent = document.getElementById('element-to-print_sales_agent_transaction_report').innerHTML;
    
            await generatePdfReportAndDownload(pdfContent, fileName);

            setDownloading(false);
    
        } catch (error) {
            console.error('Error in downloading PDF:', error);
        }
    };

    const handleDownloadExcel = async () => {
        try {
            setDownloading(true);
    
            const { agentId, status } = getValues();
    
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : undefined;
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : undefined;
    
            const listResponse = await getAllTransactionsHandledByAgentList(agentId, status, formattedStartDate, formattedEndDate);
    
            setAllReportList(listResponse);
    
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sales Agent Transactions');
    
            // Add the company header (image and information)
            await addCompanyHeader(worksheet, 'Sales Agent Transactions Report', formattedStartDate, formattedEndDate);
    
            worksheet.getRow(12).height = 20;
            worksheet.getRow(13).height = 20;
    
            // Start the column header in A14
            const headerRow = worksheet.addRow([
                'Quotation Number',
                'Quotation Date',
                'Status',
                'Total Base Amount',
                'Total Markup Amount',
                'Client Name',
                'Shipment Type',
                'Delivery Period',
                'Approved Date',
                'Agent Name',
                'Agent Email',
                'Agent Employee ID',
            ]);
    
            headerRow.eachCell((cell) => {
                cell.font = { bold: true }; // Make text bold
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFEAEAEA' } // Set background color to #eee
                };
                cell.alignment = { horizontal: 'center' }; // Center-align text
            });
    
            // Set column widths for better visibility
            worksheet.columns = [
                { key: 'quotationNumber', width: 20 },
                { key: 'quotationDate', width: 20 },
                { key: 'status', width: 20 },
                { key: 'totalBaseAmount', width: 20 },
                { key: 'totalMarkUpAmount', width: 20 },
                { key: 'clientName', width: 30 },
                { key: 'shipmentType', width: 15 },
                { key: 'deliveryPeriod', width: 15 },
                { key: 'approvedDate', width: 15 },
                { key: 'agentName', width: 25 },
                { key: 'agentEmail', width: 30 },
                { key: 'agentEmployeeId', width: 20 },
            ];
    
            // Add data rows starting from row 15 (A15)
            listResponse.forEach((item, index) => {
                const rowNumber = index + 15; // Start adding rows after the column header (row 14)
                worksheet.getCell(`A${rowNumber}`).value = item.quotationNumber;
                worksheet.getCell(`B${rowNumber}`).value = moment(item.quotationDate).format('YYYY-MM-DD');
                worksheet.getCell(`C${rowNumber}`).value = item.status;
                worksheet.getCell(`D${rowNumber}`).value = formatCurrency(parseFloat(item.totalBaseAmount)) || 0.00;
                worksheet.getCell(`E${rowNumber}`).value = formatCurrency(parseFloat(item.totalMarkUpAmount)) || 0.00;
                worksheet.getCell(`F${rowNumber}`).value = item.clientName;
                worksheet.getCell(`G${rowNumber}`).value = item.shipmentType;
                worksheet.getCell(`H${rowNumber}`).value = item.deliveryPeriod;
                worksheet.getCell(`I${rowNumber}`).value = item.approvedDate ? moment(item.approvedDate).format('YYYY-MM-DD') : '';
                worksheet.getCell(`J${rowNumber}`).value = item.agent.name;
                worksheet.getCell(`K${rowNumber}`).value = item.agent.email;
                worksheet.getCell(`L${rowNumber}`).value = item.agent.employeeId;
            });
    
            // Save the Excel file
            const excelFileName = `sales_agent_transaction_report_${formattedStartDate}_${formattedEndDate}.xlsx`;
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer]), excelFileName);
            });
    
            setDownloading(false);
    
        } catch (error) {
            console.log("Error while generating sales agent transactions report in Excel: ", error);
            setDownloading(false);
        }
    };

    const handleDownloadReport = async () => {
        try {
            setDownloading(true);
            const { agentId, status } = getValues();
    
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : undefined;
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : undefined;

            const listResponse = await getAllTransactionsHandledByAgentList(agentId, status, formattedStartDate, formattedEndDate);

            setAllReportList(listResponse);

            setTimeout(() => {
                handleDownloadPdf();
            }, 100);
        } catch (error) {
            console.log("Error while generating sales agent transactions report: ", error);
        }
    }

    useEffect(() => {
        dispatch(setPortalPage('Sales Agent Transaction - Reports'));
    }, [dispatch]);

    useEffect(() => {
        getSalesAgents().then(res => {
            setSalesAgents([{ id: 'All', name: 'All' }, ...res]);
        });
    }, [getSalesAgents, setSalesAgents])

    return (
        <>
            <Card border="light" className="table-wrapper table-responsive shadow-sm card-wrapper-full-height">
                <Card.Body className="pt-0">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-4 mb-md-0 search-block-width-600">
                            <p className="mb-0">Transaction handled by Sales Agent Report</p>
                        </div>
                    </div>
                    <div className="table-settings mb-2">
                        <Row className="justify-content-between align-items-center">
                            <Col md={3}>
                                <Form.Group id="filter-user">
                                    <Form.Label className="light-font">Sales Agents</Form.Label>
                                    <Form.Select 
                                        {...register('agentId')}
                                    >
                                        <option value="">Select Agent</option>
                                        {salesAgents.map((item, i) => (
                                            <option key={i} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {errors.agentId?.message && (
                                        <small className="invalid-fields">{errors.agentId.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group id="filter-type">
                                    <Form.Label className="light-font">Filter Status</Form.Label>
                                    <Form.Select 
                                        {...register('status')}
                                    >
                                        <option value="">Select Status</option>
                                        {transactionStatuses.map((status, i) => (
                                            <option key={i} value={status.value}>
                                                {status.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {errors.status?.message && (
                                        <small className="invalid-fields">{errors.status.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group id="start-date">
                                    <Form.Label className="light-font">Start Date</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={handleStartDateChange}
                                        closeOnSelect={true}
                                        renderInput={(props, openCalendar) => (
                                        <InputGroup>
                                            <InputGroup.Text>
                                            <FontAwesomeIcon icon={faCalendar} />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                name="startDate"
                                                value={startDate ? moment(startDate).format('MM/DD/YYYY') : ''}
                                                placeholder="Select date"
                                                onFocus={openCalendar}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                autoComplete="off"
                                            />
                                        </InputGroup>
                                        )}
                                    />
                                    {errors.startDate?.message && (
                                        <small className="invalid-fields">{errors.startDate.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group id="end-date">
                                    <Form.Label className="light-font">End Date</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={handleEndDateChange}
                                        closeOnSelect={true}
                                        renderInput={(props, openCalendar) => (
                                        <InputGroup>
                                            <InputGroup.Text>
                                            <FontAwesomeIcon icon={faCalendar} />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                name="endDate"
                                                value={endDate ? moment(endDate).format('MM/DD/YYYY') : ''}
                                                placeholder="Select date"
                                                onFocus={openCalendar}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                autoComplete="off"
                                            />
                                        </InputGroup>
                                        )}
                                    />
                                    {errors.endDate?.message && (
                                        <small className="invalid-fields">{errors.endDate.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="table-settings mb-5">
                        <Row>
                            <Col md={12}>
                                <Button variant="primary" type="button" onClick={() => handleGenerateReport()}>
                                    Search
                                </Button>
                                <Button className='ms-1' variant="secondary" type="button" onClick={() => resetFilters()}>
                                    Reset
                                </Button>
                                {reportList.length > 0 && (
                                    <ButtonGroup className="pull-right ms-2">
                                        <Button variant="primary" size="sm" type="button" onClick={() => handleDownloadReport()}>
                                            <FontAwesomeIcon icon={faFilePdf} /> {`${downloading ? 'Downloading...' : 'Download PDF'}`}
                                        </Button>
                                        <Button variant="secondary" size="sm" type="button" onClick={() => handleDownloadExcel()}>
                                            <FontAwesomeIcon icon={faFileExcel} /> {`${downloading ? 'Downloading...' : 'Download Excel'}`}
                                        </Button>
                                    </ButtonGroup>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <Table hover className="billing-table-list align-items-center">
                        <thead>
                            <tr>
                                <th className="border-bottom">Quotation Number</th>
                                <th className="border-bottom">Quotation Date</th>
                                <th className="border-bottom">Status</th>
                                <th className="border-bottom">Total Base Amount</th>
                                <th className="border-bottom">Total Markup Amount</th>
                                <th className="border-bottom">Client Name</th>
                                <th className="border-bottom">Shipment Type</th>
                                <th className="border-bottom">Delivery Period</th>
                                <th className="border-bottom">Approved Date</th>
                                <th className="border-bottom">Agent Name</th>
                                <th className="border-bottom">Agent Email</th>
                                <th className="border-bottom">Agent Employee ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportList.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.quotationNumber
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading  ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                moment(item.quotationDate).format('YYYY-MM-DD')
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading  ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.status
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading  ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                formatCurrency(parseFloat(item.totalBaseAmount)) || 0.00
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading  ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                formatCurrency(parseFloat(item.totalMarkUpAmount)) || 0.00
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading  ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.clientName
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading  ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.shipmentType
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading  ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.deliveryPeriod
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading  ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.approvedDate ? moment(item.approvedDate).format('YYYY-MM-DD') : ''
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading  ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.agent.name
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading  ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.agent.email
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading  ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.agent.employeeId
                                            )}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {!loading && (
                        <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={handlePageEvent} />
                    )}
                </Card.Body>
            </Card>
            <div className='report-element-to-print' id='element-to-print_sales_agent_transaction_report'>
                <SalesAgentTransactionsPreview startDate={startDate} endDate={endDate} reportList={allReportList}/>
            </div>
        </>
    )
}

export default SalesAgentTransactions;
