import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPortalPage } from '../actions/blanket';
import { Card, Button, Table, Row, Col, Form, InputGroup, ButtonGroup } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import reportApi from '../services/report-service';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import './styles.scss';
import { generatePdfReportAndDownload } from '../pdfGenerator';
import { formatCurrency } from "../utils/formatter";
import CommissionCalculationPreview from "./commissioncalculationpreview";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { addCompanyHeader } from "../excelreportgenerator";

const CommissionCalculation = () => {

    const defaultStartDate = moment().startOf('month').toDate();
    const defaultEndDate = moment().endOf('month').toDate();

    const dispatch = useDispatch();
    const { getCommissionCalculation } = reportApi;
    const loading = useSelector((state) => state.blanket.showAdminLoader);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [reportList, setReportList] = useState([]);
    const [downloading, setDownloading] = useState(false);

    // Calculate overall totals for total amount and commission
    const overallTotalBaseAmount = reportList.reduce((sum, item) => sum + parseFloat(item.totalBaseAmount || 0), 0).toFixed(2);
    const overallTotalMarkUpAmount = reportList.reduce((sum, item) => sum + parseFloat(item.totalMarkUpAmount || 0), 0).toFixed(2);
    const overallTotalCommission = reportList.reduce((sum, item) => sum + parseFloat(item.commissionAmount || 0), 0).toFixed(2);

    // Updated form validation schema with startDate and endDate required
    const formSchema = Yup.object().shape({
        startDate: Yup.string().nullable().required('Start date is required')
            .test('start-date-test', 'Start date cannot be greater than end date', function(value) {
                const { endDate } = this.parent;
                return !value || !endDate || moment(value).isSameOrBefore(moment(endDate));
            }),
        endDate: Yup.string().nullable().required('End date is required')
            .test('end-date-test', 'End date cannot be earlier than start date', function(value) {
                const { startDate } = this.parent;
                return !value || !startDate || moment(value).isSameOrAfter(moment(startDate));
            }),
    });

    const formOptions = {
        resolver: yupResolver(formSchema),
        mode: 'all',
        defaultValues: {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        }
    };

    const { getValues, trigger, formState, setValue, reset } = useForm(formOptions);
    const { errors } = formState;

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setValue('startDate', date, { shouldValidate: true });
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setValue('endDate', date, { shouldValidate: true });
    };

    const resetFilters = () => {
        reset({
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        });
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setReportList([]);
    };

    const handleGenerateReport = async () => {
        try {
            const isValid = await trigger();

            if (!isValid) {
                return;
            }

            const { startDate, endDate } = getValues();

            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : undefined;
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : undefined;

            const listResponse = await getCommissionCalculation(formattedStartDate, formattedEndDate);

            setReportList(listResponse);
        } catch (error) {
            console.log("Error while generating commission calculation report: ", error);
        }
    };

    const handleDownloadPdf = async () => {
        try {
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : 'start';
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : 'end';
    
            const fileName = `commission_calculation_report_${formattedStartDate}_${formattedEndDate}.pdf`;
            const pdfContent = document.getElementById('element-to-print-commission-calculation-report').innerHTML;
    
            await generatePdfReportAndDownload(pdfContent, fileName);

            setDownloading(false);
    
        } catch (error) {
            console.error('Error in downloading PDF:', error);
        }
    };

    const handleDownloadReport = async () => {
        try {
            setDownloading(true);

            setTimeout(() => {
                handleDownloadPdf();
            }, 100);
        } catch (error) {
            console.log("Error while generating commission calculation report: ", error);
        }
    };

    const handleDownloadExcel = async () => {
        try {
            setDownloading(true);
    
            const { startDate, endDate } = getValues();
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : undefined;
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : undefined;
    
            const listResponse = await getCommissionCalculation(formattedStartDate, formattedEndDate);
    
            // Create a new workbook
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Commission Calculation');
    
            await addCompanyHeader(worksheet, 'Commission Calculation Report', formattedStartDate, formattedEndDate);
    
            worksheet.getRow(12).height = 20;
            worksheet.getRow(13).height = 20;
    
            // Add column headers starting from A14
            const headerRow = worksheet.addRow([
                'Quotation Number',
                'Quotation Date',
                'Client Name',
                'Total Base Amount',
                'Total Markup Amount',
                'Commission Amount',
                'Status',
                'Agent Name',
                'Agent Email',
                'Agent Employee ID',
            ]);
    
            // Make column headers bold and add background color
            headerRow.eachCell((cell) => {
                cell.font = { bold: true }; // Make text bold
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFEAEAEA' } // Set background color to #eee
                };
                cell.alignment = { horizontal: 'center' }; // Center-align text
            });
    
            // Set column widths for better visibility
            worksheet.columns = [
                { key: 'quotationNumber', width: 20 },
                { key: 'quotationDate', width: 20 },
                { key: 'clientName', width: 30 },
                { key: 'totalBaseAmount', width: 20 },
                { key: 'totalMarkUpAmount', width: 20 },
                { key: 'commissionAmount', width: 20 },
                { key: 'status', width: 15 },
                { key: 'agentName', width: 25 },
                { key: 'agentEmail', width: 30 },
                { key: 'agentEmployeeId', width: 20 },
            ];
    
            // Add data rows
            listResponse.forEach((item, index) => {
                worksheet.addRow([
                    item.quotationNumber,
                    moment(item.quotationDate).format('YYYY-MM-DD'),
                    item.clientName,
                    formatCurrency(parseFloat(item.totalBaseAmount)) || 0.00,
                    formatCurrency(parseFloat(item.totalMarkUpAmount)) || 0.00,
                    formatCurrency(parseFloat(item.commissionAmount)) || 0.00,
                    item.status,
                    item.agent.name,
                    item.agent.email,
                    item.agent.employeeId,
                ]);
            });
    
            // Add overall totals at the bottom of the data
            const lastRow = listResponse.length + 14; // Calculate the row where totals should be inserted (data starts from row 14)
    
            // Add Overall Total Base Amount in Column A
            worksheet.getCell(`A${lastRow + 1}`).value = 'Overall Total Base Amount:';
            worksheet.getCell(`A${lastRow + 1}`).font = { bold: true };
            worksheet.getCell(`B${lastRow + 1}`).value = formatCurrency(parseFloat(overallTotalBaseAmount)) || 0.00;
    
            // Add Overall Total Mark-Up Amount in Column D
            worksheet.getCell(`D${lastRow + 1}`).value = 'Overall Total Mark-Up Amount:';
            worksheet.getCell(`D${lastRow + 1}`).font = { bold: true };
            worksheet.getCell(`E${lastRow + 1}`).value = formatCurrency(parseFloat(overallTotalMarkUpAmount)) || 0.00;
    
            // Add Overall Total Commission in Column G
            worksheet.getCell(`G${lastRow + 1}`).value = 'Overall Total Commission:';
            worksheet.getCell(`G${lastRow + 1}`).font = { bold: true };
            worksheet.getCell(`H${lastRow + 1}`).value = formatCurrency(parseFloat(overallTotalCommission)) || 0.00;
    
            // Save the Excel file
            const excelFileName = `commission_calculation_report_${formattedStartDate}_${formattedEndDate}.xlsx`;
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer]), excelFileName);
            });
    
            setDownloading(false);
        } catch (error) {
            console.log("Error while generating Excel report: ", error);
            setDownloading(false);
        }
    };    

    useEffect(() => {
        dispatch(setPortalPage('Commission Calculation - Reports'));
    }, [dispatch]);

    return (
        <>
            <Card border="light" className="table-wrapper table-responsive shadow-sm card-wrapper-full-height">
                <Card.Body className="pt-0">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-4 mb-md-0">
                            <p className="mb-0">Commission Calculation Report</p>
                        </div>
                    </div>
                    <div className="table-settings mb-2">
                        <Row className="justify-content-between align-items-center">
                            <Col md={6}>
                                <Form.Group id="start-date">
                                    <Form.Label>Start Date</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={handleStartDateChange}
                                        closeOnSelect={true}
                                        renderInput={(props, openCalendar) => (
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faCalendar} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="startDate"
                                                    value={startDate ? moment(startDate).format('MM/DD/YYYY') : ''}
                                                    placeholder="Select date"
                                                    onFocus={openCalendar}
                                                    autoComplete="off"
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    {errors.startDate?.message && (
                                        <small className="invalid-fields">{errors.startDate.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group id="end-date">
                                    <Form.Label>End Date</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={handleEndDateChange}
                                        closeOnSelect={true}
                                        renderInput={(props, openCalendar) => (
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faCalendar} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    name="endDate"
                                                    value={endDate ? moment(endDate).format('MM/DD/YYYY') : ''}
                                                    placeholder="Select date"
                                                    onFocus={openCalendar}
                                                    autoComplete="off"
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    {errors.endDate?.message && (
                                        <small className="invalid-fields">{errors.endDate.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="table-settings mb-5">
                        <Row>
                            <Col md={12}>
                                <Button variant="primary" onClick={() => handleGenerateReport()}>
                                    Search
                                </Button>
                                <Button className="ms-2" variant="secondary" onClick={() => resetFilters()}>
                                    Reset
                                </Button>

                                {reportList.length > 0 && (
                                    <ButtonGroup className="pull-right ms-2">
                                        <Button variant="primary" size="sm" type="button" onClick={() => handleDownloadReport()}>
                                            <FontAwesomeIcon icon={faFilePdf} /> {`${downloading ? 'Downloading...' : 'Download PDF'}`}
                                        </Button>
                                        <Button variant="secondary" size="sm" type="button" onClick={() => handleDownloadExcel()}>
                                            <FontAwesomeIcon icon={faFileExcel} /> {`${downloading ? 'Downloading...' : 'Download Excel'}`}
                                        </Button>
                                    </ButtonGroup>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <Table hover className="billing-table-list align-items-center">
                        <thead>
                            <tr>
                                <th className="border-bottom">Quotation Number</th>
                                <th className="border-bottom">Quotation Date</th>
                                <th className="border-bottom">Client Name</th>
                                <th className="border-bottom">Total Base Amount</th>
                                <th className="border-bottom">Total Markup Amount</th>
                                <th className="border-bottom">Commission Amount</th>
                                <th className="border-bottom">Status</th>
                                <th className="border-bottom">Agent Name</th>
                                <th className="border-bottom">Agent Email</th>
                                <th className="border-bottom">Agent Employee ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportList.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.quotationNumber
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                moment(item.quotationDate).format('YYYY-MM-DD')
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.clientName
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                formatCurrency(parseFloat(item.totalBaseAmount)) || 0.00
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                formatCurrency(parseFloat(item.totalMarkUpAmount)) || 0.00
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                formatCurrency(parseFloat(item.commissionAmount)) || 0.00
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.status
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.agent.name
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.agent.email
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="fw-normal">
                                            {loading ? (
                                                <Skeleton wrapper={SkeletonWrapper} />
                                            ) : (
                                                item.agent.employeeId
                                            )}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {reportList.length > 0 && (
                        <div className="mt-4">
                            <Row>
                                <Col md={4}>
                                    <h4><strong>Overall Total Base Amount:</strong> {loading ? <Skeleton /> : `${formatCurrency(parseFloat(overallTotalBaseAmount)) || 0.00}`}</h4>
                                </Col>
                                <Col md={5}>
                                    <h4><strong>Overall Total Mark-Up Amount:</strong> {loading ? <Skeleton /> : `${formatCurrency(parseFloat(overallTotalMarkUpAmount)) || 0.00}`}</h4>
                                </Col>
                                <Col md={3}>
                                    <h4><strong>Overall Total Commission:</strong> {loading ? <Skeleton /> : `${formatCurrency(parseFloat(overallTotalCommission)) || 0.00}`}</h4>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Card.Body>
            </Card>
            <div className='report-element-to-print' id='element-to-print-commission-calculation-report'>
                <CommissionCalculationPreview 
                    startDate={startDate} 
                    endDate={endDate} 
                    reportList={reportList} 
                    overallTotalBaseAmount={overallTotalBaseAmount}
                    overallTotalMarkUpAmount={overallTotalMarkUpAmount}
                    overallTotalCommission={overallTotalCommission}
                />
            </div>
        </>
    );
};

export default CommissionCalculation;
