import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPortalPage } from "../actions/blanket";
import { Card, Button, Table, Row, Col, Form, InputGroup, ButtonGroup } from "@themesberg/react-bootstrap";
import { useForm } from "react-hook-form";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faFileDownload, faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import reportApi from "../services/report-service";
import Skeleton from "react-loading-skeleton";
import { SkeletonWrapper } from "../components/TableRowWrapper";
import { generatePdfReportAndDownload } from "../pdfGenerator";
import ClientPercentagePreview from "./clientpercentagepreview";
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { addCompanyHeader } from "../excelreportgenerator";

const ClientPercentage = () => {
  const defaultStartDate = moment().startOf("month").toDate();
  const defaultEndDate = moment().endOf("month").toDate();

  const dispatch = useDispatch();
  const { getClientPercentageByLoadingDate } = reportApi;
  const loading = useSelector((state) => state.blanket.showAdminLoader);

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [containerNumber, setContainerNumber] = useState("");
  const [reportList, setReportList] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const formSchema = Yup.object().shape({
    startDate: Yup.string()
      .nullable()
      .required("Start date is required")
      .test("start-date-test", "Start date cannot be greater than end date", function (value) {
        const { endDate } = this.parent;
        return !value || !endDate || moment(value).isSameOrBefore(moment(endDate));
      }),
    endDate: Yup.string().nullable(),
  });

  const formOptions = {
    resolver: yupResolver(formSchema),
    mode: "all",
    defaultValues: {
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
  };

  const { trigger, formState, setValue, getValues, reset } = useForm(formOptions);
  const { errors } = formState;

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setValue("startDate", date, { shouldValidate: true });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setValue("endDate", date, { shouldValidate: true });
  };

  const resetFilters = () => {
    reset({
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    });
    setContainerNumber("");
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setReportList([]);
  };

  const handleGenerateReport = async () => {
    try {
      const isValid = await trigger();
      if (!isValid) return;

      const formattedStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : undefined;
      const formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : undefined;

      const response = await getClientPercentageByLoadingDate(containerNumber, formattedStartDate, formattedEndDate);
      setReportList(response?.data || []);
    } catch (error) {
      console.error("Error while generating client percentage report:", error);
    }
  };

  const handleDownloadPdf = async () => {
    try {
      const formattedStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "start";
      const formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "end";
      const fileName = `client_percentage_report_${formattedStartDate}_${formattedEndDate}.pdf`;
      const pdfContent = document.getElementById("element-to-print_client_percentage").innerHTML;
      await generatePdfReportAndDownload(pdfContent, fileName);
      setDownloading(false);
    } catch (error) {
      console.error("Error in downloading PDF:", error);
    }
  };

  const handleDownloadExcel = async () => {
    try {
        setDownloading(true);

        const { startDate, endDate } = getValues();
        const formattedStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : undefined;
        const formattedEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : undefined;

        // Fetch the report data
        const response = await getClientPercentageByLoadingDate(containerNumber, formattedStartDate, formattedEndDate);
        const reportData = response?.data || [];

        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Client Percentage Report");

        // Add company header, title, and date range to the worksheet
        await addCompanyHeader(worksheet, 'Client Percentage Report', formattedStartDate, formattedEndDate);

        worksheet.getRow(12).height = 20;
        worksheet.getRow(13).height = 20;

        // Add column headers
        const headerRow = worksheet.addRow([
            'Container Number',
            'Loading Date',
            'Total CBM',
            'Local Client CBM Percentage',
            'China Client CBM Percentage'
        ]);

        // Style the column headers
        headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFEAEAEA' }
            };
            cell.alignment = { horizontal: 'center' };
        });

        // Set column widths for better visibility
        worksheet.columns = [
            { key: 'containerNumber', width: 20 },
            { key: 'loadingDate', width: 20 },
            { key: 'totalCBM', width: 15 },
            { key: 'localCBMPercentage', width: 25 },
            { key: 'chinaCBMPercentage', width: 25 }
        ];

        // Add data rows
        reportData.forEach((item) => {
            worksheet.addRow([
                item.containerNumber || "N/A",
                moment(item.loadingDate).format("YYYY-MM-DD"),
                (item.totalCBM ?? 0).toFixed(2),
                `${(item.localCBMPercentage ?? 0).toFixed(2)}% (${(item.localTotalCbm ?? 0).toFixed(2)})`,
                `${(item.chinaCBMPercentage ?? 0).toFixed(2)}% (${(item.chinaTotalCbm ?? 0).toFixed(2)})`
            ]);
        });

        // Save the Excel file
        const excelFileName = `client_percentage_report_${formattedStartDate}_${formattedEndDate}.xlsx`;
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer]), excelFileName);
        });

        setDownloading(false);
    } catch (error) {
        console.log("Error while generating Excel report: ", error);
        setDownloading(false);
    }
  };


  const handleDownloadReport = async () => {
    try {
      setDownloading(true);
      setTimeout(() => handleDownloadPdf(), 100);
    } catch (error) {
      console.error("Error while generating client percentage report:", error);
    }
  };

  useEffect(() => {
    dispatch(setPortalPage("Percentage of Cebu Clients & China Clients (OC4D) per container or per month"));
  }, [dispatch]);

  return (
    <>
      <Card border="light" className="table-wrapper table-responsive shadow-sm card-wrapper-full-height">
        <Card.Body className="pt-0">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
              <p className="mb-0">Percentage of Cebu Clients & China Clients (OC4D) per container or per month</p>
            </div>
          </div>
          <div className="table-settings mb-5">
            <Row className="justify-content-between align-items-center">
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Container Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={containerNumber}
                    onChange={(e) => setContainerNumber(e.target.value)}
                    placeholder="Enter container number"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group id="start-date">
                  <Form.Label>Start Date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    onChange={handleStartDateChange}
                    closeOnSelect={true}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={startDate ? moment(startDate).format("MM/DD/YYYY") : ""}
                          placeholder="Select date"
                          onFocus={openCalendar}
                          autoComplete="off"
                        />
                      </InputGroup>
                    )}
                  />
                  {errors.startDate?.message && (
                    <small className="invalid-fields">{errors.startDate.message}</small>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group id="end-date">
                  <Form.Label>End Date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    onChange={handleEndDateChange}
                    closeOnSelect={true}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={endDate ? moment(endDate).format("MM/DD/YYYY") : ""}
                          placeholder="Select date"
                          onFocus={openCalendar}
                          autoComplete="off"
                        />
                      </InputGroup>
                    )}
                  />
                  {errors.endDate?.message && (
                    <small className="invalid-fields">{errors.endDate.message}</small>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mt-4">
                <Button variant="primary" type="button" onClick={() => handleGenerateReport()}>
                  Search
                </Button>
                <Button className="ms-2" variant="secondary" type="button" onClick={() => resetFilters()}>
                  Reset
                </Button>
                {reportList.length > 0 && (
                    <ButtonGroup className="pull-right ms-2">
                        <Button variant="primary" size="sm" type="button" onClick={() => handleDownloadReport()}>
                            <FontAwesomeIcon icon={faFilePdf} /> {`${downloading ? 'Downloading...' : 'Download PDF'}`}
                        </Button>
                        <Button variant="secondary" size="sm" type="button" onClick={() => handleDownloadExcel()}>
                            <FontAwesomeIcon icon={faFileExcel} /> {`${downloading ? 'Downloading...' : 'Download Excel'}`}
                        </Button>
                    </ButtonGroup>
                )}
              </Col>
            </Row>
          </div>
          <Table hover className="billing-table-list align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Container Number</th>
                <th className="border-bottom">Loading Date</th>
                <th className="border-bottom">Total CBM</th>
                <th className="border-bottom">Local Client CBM Percentage</th>
                <th className="border-bottom">China Client CBM Percentage</th>
              </tr>
            </thead>
            <tbody>
              {reportList.length > 0 ? (
                reportList.map((item, index) => (
                  <tr key={index}>
                    <td>{loading ? <Skeleton wrapper={SkeletonWrapper} /> : item.containerNumber || "N/A"}</td>
                    <td>{loading ? <Skeleton wrapper={SkeletonWrapper} /> : moment(item.loadingDate).format("YYYY-MM-DD")}</td>
                    <td>{loading ? <Skeleton wrapper={SkeletonWrapper} /> : (item.totalCBM ?? 0).toFixed(2)}</td>
                    <td>
                      {loading ? (
                        <Skeleton wrapper={SkeletonWrapper} />
                      ) : (
                        `${(item.localCBMPercentage ?? 0).toFixed(2)}% (${(item.localTotalCbm ?? 0).toFixed(2)})`
                      )}
                    </td>
                    <td>
                      {loading ? (
                        <Skeleton wrapper={SkeletonWrapper} />
                      ) : (
                        `${(item.chinaCBMPercentage ?? 0).toFixed(2)}% (${(item.chinaTotalCbm ?? 0).toFixed(2)})`
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    {loading ? <Skeleton wrapper={SkeletonWrapper} height={40} /> : "No data found for the selected filters."}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <div className="report-element-to-print" id="element-to-print_client_percentage">
        <ClientPercentagePreview
          startDate={startDate}
          endDate={endDate}
          containerNumber={containerNumber}
          reportList={reportList}
        />
      </div>
    </>
  );
};

export default ClientPercentage;