import React from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import './styles.scss';
import SeaRoadBrand from '../assets/img/brand/sea-road-dark.png';
import { formatCurrency } from "../utils/formatter";
import { DEFAULT_PH_LOCALE_DATE_FORMAT, RMBConversionMarkup } from "../utils/constants";

const BillingDocumentPreview = (props) => {
    const {billingDocumentData} = props;

    const billingDescription = billingDocumentData.items.map(item => item.itemDescription).join(' / ');

    // Mask the container number
    const containerNumber = billingDocumentData.containerNumber;
    const maskedContainerNumber = `${containerNumber.slice(0, 4)}****${containerNumber.slice(-3)}`;

    // Format the billing date using Philippines date format
    const billingDate = new Date(billingDocumentData.billingDate);
    const formattedBillingDate = billingDate.toLocaleDateString(DEFAULT_PH_LOCALE_DATE_FORMAT, {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
    });

    const rmbConversionAndMarkUp = billingDocumentData.containerRMBConversion + parseFloat(RMBConversionMarkup);


    // Calculate subtotals
    let subTotalPackaging = 0;
    let subTotalVolume = 0;
    let subTotalWeight = 0;

    const subTotalFreightCharge = billingDocumentData.items[0]?.itemFreightCharge || 0;
    const totalBillableAmount = billingDocumentData.totalBillableAmount;
    let subTotalSensitiveCharge = 0;
    let subTotalForkliftCharge = 0;
    let subTotalInlandTransportation = 0;
    let totalSensitiveFound = 0;
    let totalForkliftFound = 0;
    let totalInlandFound = 0;
    billingDocumentData.items.forEach(item => {
        subTotalPackaging += item.numberOfPackage;
        subTotalVolume += item.cbm;
        subTotalWeight += item.grossWeight;
        subTotalSensitiveCharge += item.itemSensitiveCharge;
        totalSensitiveFound += item.sensitiveCharge;
        subTotalForkliftCharge += item.forkliftCharge;
        subTotalInlandTransportation += item.inlandTransportation;
        totalForkliftFound += item.forklift;
        totalInlandFound += item.inland;
    });

    let displayCollection = [
        { title: 'ProductDetails', value: 1 },
        { title: 'freightCharge', value: subTotalFreightCharge },
        { title: 'sensitiveCharge', value: subTotalSensitiveCharge },
        { title: 'forkliftCharge', value: subTotalForkliftCharge },
        { title: 'inlandCharge', value: subTotalInlandTransportation },
    ].filter(item => item.value !== 0);

    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const labeledDisplayCollection = displayCollection.map((item, index) => ({
        label: alphabet[index],
        ...item
    }));

    const getDisplayCollectionByTitle = (title) => {
        return labeledDisplayCollection.find(item => item.title === title) || null;
    };

    const displayAppliedUnit = (categoryUnit) => {
        if(categoryUnit === "CBM") return "cubic meter";
        return "kilogram"
    }

    const displaySubTotalCBMorKG = (categoryUnit) => {
        if(categoryUnit === "CBM") return subTotalVolume;
        return subTotalWeight
    }

    const displaySubTotalUnit = (categoryUnit) => {
        if(categoryUnit === "CBM") return "cu.m";
        return "kg"
    }

    const getApplidRateObject = () => {
        if (billingDocumentData.appliedRateData && Object.keys(billingDocumentData.appliedRateData).length !== 0) {
            return billingDocumentData.appliedRateData;
        }

        return billingDocumentData.appliedClientSpecialRateData;
    }

    const getSpecialRateValue = (rateData) => {

        if(rateData.rate) return rateData.rate
        return rateData.fixRate ? rateData.fixRate : rateData.bracketRate;
    }

    function getTrackingReferences(data) {
        let trackingReferences = [];
    
        if (data.items && Array.isArray(data.items)) {
            trackingReferences = data.items
                .map(item => item.trackingReference) 
                .filter(ref => ref && ref.trim() !== '') 
                .join(', '); 
    
            if (!trackingReferences) {
                trackingReferences = data.trackingReference;
            }
        } else {
            trackingReferences = data.trackingReference;
        }
    
        return trackingReferences;
    }
    
    
    return (
        <Card className="bg-white shadow-sm mb-4" style={{width: '100%'}}>
            <Card.Body>
                <Row>
                    <Col md="12">
                        <div className="preview-container">
                            <div className="preview-header">
                                <Image src={SeaRoadBrand} className="navbar-brand-light" alt="SEA ROAD TRADING INC." />
                                <div className="company-info">
                                    <h2>SEA ROAD TRADING INC.</h2>
                                    <p>Gochan Compound, Warehouse 5B, MJ Cuenco Avenue, Mabolo, Cebu City, Philippines</p>
                                    <p>Telephone No.: (032) 434-6453 | TIN#: 274-454-701-000</p>
                                </div>
                            </div>
                            <div className="border-line-up"></div>
                            <div className="border-line-down"></div>
                        </div>
                    </Col>
                </Row>
                <div className="preview-body clearfix mb-5">
                    <h1>Billing Statement</h1>
                    <div className="left">
                        <p>To: {`${billingDocumentData.clientData.displayName}`}</p>
                        <p>{billingDocumentData.clientRefWithFcc}</p>
                        <p>&nbsp;</p>
                        <p>Re: Billing For {billingDescription}</p>
                    </div>
                    <div className="right">
                        <p>Date: {formattedBillingDate}</p>
                        <p>Container No.: {maskedContainerNumber}</p>
                        <p>Invoice No.: {billingDocumentData.invoiceNumber}</p>
                        {billingDocumentData.items.length === 1 ? (
                            <p>Tracking No.: {getTrackingReferences(billingDocumentData)}</p>
                        ) : (
                            <div>
                                <p>Tracking No.: </p>
                                <div className="tracking-content"> 
                                    {getTrackingReferences(billingDocumentData)}
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>
                <p>{`${getDisplayCollectionByTitle("ProductDetails")?.label}.`} Product Details:</p>
                <div className="preview-responsive">
                    <table className="tbl-document-preview">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Specifications</th>
                                <th>Quantity <span>(PCS)</span></th>
                                <th>Packaging <span>(TOT. CTNs)</span></th>
                                <th>Volume <span>(CBM)</span></th>
                                <th>Weight <span>(KG)</span></th>
                                <th>Unit<span>Cost (PHP/PQ)</span></th>
                                <th>Total Product<span>Cost(RMB)</span></th>
                                <th>Total Product<span>Cost(PHP)</span></th>
                                <th>Sensitive <span>Charge(PHP)</span></th>
                                <th>Inland<span>Cost (PHP)</span></th>
                                <th>Forklift<span>Cost (PHP)</span></th>
                                {/* <th>Freight<span>Charge (PHP)</span></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {billingDocumentData.items.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.itemDescription}</td>
                                    <td></td>
                                    <td>-</td>
                                    <td>{item.numberOfPackage}</td>
                                    <td>{item.cbm.toFixed(2)}</td>
                                    <td>{item.grossWeight}</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>{formatCurrency(parseFloat(item.itemSensitiveCharge))}</td>
                                    <td>{formatCurrency(parseFloat(item.inlandTransportation))}</td>
                                    <td>{formatCurrency(parseFloat(item.forkliftCharge))}</td>
                                    {/* <td>{formatCurrency(parseFloat(item.itemFreightCharge))}</td> */}
                                </tr>
                            ))}
                            <tr className="sub-total-row">
                                <td colSpan={3} className="sub-total">SUB-TOTAL</td>
                                <td>{subTotalPackaging}</td>
                                <td>{subTotalVolume.toFixed(2)}</td>
                                <td>{subTotalWeight}</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>{formatCurrency(parseFloat(subTotalSensitiveCharge))}</td>
                                <td>{formatCurrency(parseFloat(subTotalInlandTransportation))}</td>
                                <td>{formatCurrency(parseFloat(subTotalForkliftCharge))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-responsive">
                    <table className="table table-freight">
                        <tbody>
                            <tr>
                                <td className="first-content" colSpan={2}>{`${getDisplayCollectionByTitle("freightCharge")?.label}.`} Freight Charge: PHP {formatCurrency(parseFloat(getSpecialRateValue(getApplidRateObject())))} per {displayAppliedUnit(getApplidRateObject().rateCategory.categoryUnit)}</td>
                                <td className="second-content" colSpan={1}>PHP {formatCurrency(parseFloat(getSpecialRateValue(getApplidRateObject())))}</td>
                                <td className="third-content" colSpan={1}>x</td>
                                <td className="forth-content">{displaySubTotalCBMorKG(getApplidRateObject().rateCategory.categoryUnit)}</td>
                                <td className="fifth-content" colSpan={1}>{displaySubTotalUnit(getApplidRateObject().rateCategory.categoryUnit)}</td>
                                <td className="sixth-content">=</td>
                                <td className="seventh-content">PHP {formatCurrency(parseFloat(subTotalFreightCharge))}</td>
                                <td className="eigth-content"> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {subTotalSensitiveCharge !== 0 && (
                    <div className="table-responsive">
                        <table className="table table-freight">
                            <tbody>
                                <tr>
                                    <td className="first-content" colSpan={2}>{`${getDisplayCollectionByTitle("sensitiveCharge")?.label}.`} Sensitive Charge: </td>
                                    <td className="second-content" colSpan={1}> {totalSensitiveFound}</td>
                                    <td className="third-content" colSpan={1}>x</td>
                                    <td className="forth-content">{rmbConversionAndMarkUp.toFixed(5)}</td>
                                    <td className="fifth-content" colSpan={1}></td>
                                    <td className="sixth-content">=</td>
                                    <td className="seventh-content">PHP {formatCurrency(parseFloat(subTotalSensitiveCharge))}</td>
                                    <td className="eigth-content"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
                {subTotalForkliftCharge !== 0 && (
                    <div className="table-responsive">
                        <table className="table table-freight">
                            <tbody>
                                <tr>
                                    <td className="first-content" colSpan={2}>{`${getDisplayCollectionByTitle("forkliftCharge")?.label}.`} Forklift Charge: </td>
                                    <td className="second-content" colSpan={1}> {totalForkliftFound}</td>
                                    <td className="third-content" colSpan={1}>x</td>
                                    <td className="forth-content">{rmbConversionAndMarkUp}</td>
                                    <td className="fifth-content" colSpan={1}></td>
                                    <td className="sixth-content">=</td>
                                    <td className="seventh-content">PHP {formatCurrency(parseFloat(subTotalForkliftCharge))}</td>
                                    <td className="eigth-content"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
                {subTotalInlandTransportation !== 0 && (
                    <div className="table-responsive">
                        <table className="table table-freight">
                            <tbody>
                                <tr>
                                    <td className="first-content" colSpan={2}>{`${getDisplayCollectionByTitle("inlandCharge")?.label}.`} Inland Charge: </td>
                                    <td className="second-content" colSpan={1}> {totalInlandFound}</td>
                                    <td className="third-content" colSpan={1}>x</td>
                                    <td className="forth-content">{rmbConversionAndMarkUp}</td>
                                    <td className="fifth-content" colSpan={1}></td>
                                    <td className="sixth-content">=</td>
                                    <td className="seventh-content">PHP {formatCurrency(parseFloat(subTotalInlandTransportation))}</td>
                                    <td className="eigth-content"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
                <div className="table-responsive">
                    <table className="table table-total-account-payable">
                        <tbody>
                            <tr>
                                <td className="first-content" colSpan={2}></td>
                                <td className="second-content" colSpan={1}></td>
                                <td className="third-content" colSpan={1}></td>
                                <td className="forth-content"></td>
                                <td className="fifth-content" colSpan={1}></td>
                                <td className="sixth-content"></td>
                                <td className="seventh-content">Total Account Payable in Peso:</td>
                                <td className="eigth-content">{formatCurrency(parseFloat(totalBillableAmount))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <table className="table table-remarks">
                    <tbody>
                        <tr>
                            <td>Remarks:</td>
                        </tr>
                        <tr>
                            <td>* Exchange Rate (Based on the Loading day): <span className="conversion">USD 1.00 = PHP <span className="figure">{billingDocumentData.containerUSDConversion}</span></span></td>
                        </tr>
                        <tr>
                            <td>* Exchange Rate (Based on the Loading day): <span className="conversion">RMB 1.000 = PHP <span className="figure">{rmbConversionAndMarkUp.toFixed(5)}</span></span></td>
                        </tr>
                        <tr>
                            <td>* Cargo (per shipment) PhP {formatCurrency(parseFloat(getSpecialRateValue(getApplidRateObject())))} times Per {getApplidRateObject().rateCategory.categoryUnit}</td>
                        </tr>
                        <tr>
                            <td>* All Charges should be collected on or before delivery.</td>
                        </tr>
                        <tr>
                            <td>* Cargo delivery: Unloading at Parking Area ONLY.</td>
                        </tr>
                        <tr>
                            <td>* Delivery: Within Cebu City, Mandaue City, Talisay City, Lapu-Lapu City ONLY.</td>
                        </tr>
                        <tr>
                            <td>* Charge to Client for heavy or oversized item that require extra manpower or equipment.</td>
                        </tr>
                        <tr>
                            <td>* Unclaimed and or Unlabelled goods shall be disposed by Sea Road 30 days from date of arrival.</td>
                        </tr>
                        <tr>
                            <td>* Freight Payment: On or before delivery date in the form of Cash, Bank Deposit, or On Date Check on date of delivery.</td>
                        </tr>
                        <tr>
                            <td>* Storage Fees: One Week Free of Charge. Succeeding days storage: Three Hundred Pesos per cubic meter per day (PHP 300/cbm/day) from the date freight Billing Statement is sent to client.</td>
                        </tr>
                        <tr className="remarks-space"><td></td></tr>
                        <tr className="deposit">
                            <td>Please deposit your payment to our BDO account only.</td>
                        </tr>
                        <tr className="account">
                            <td>Account Name: <span>ELEVEN SEAS CEBU LOGISTICS INC.</span></td>
                        </tr>
                        <tr className="account">
                            <td>Account Number: <span>0069-9802-2496</span></td>
                        </tr>
                        <tr className="remarks-space"><td></td></tr>
                        <tr className="closing">
                            <td>"This document is not valid for claiming input taxes."</td>
                        </tr>
                    </tbody>
                </table>
            </Card.Body>
        </Card>
    );
}

export default BillingDocumentPreview;
