import html2pdf from 'html2pdf.js';

export const generatePdf = (content, filename, options = {}) => {
  const element = document.createElement('div');
  element.innerHTML = content;

  const opt = {
    margin: 0,
    filename: filename,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
    jsPDF: { unit: 'px', format: [2400, 1600], orientation: 'landscape' }, // Set larger format and landscape orientation
    ...options,
  };

  // Append the element to the body temporarily
  document.body.appendChild(element);

  // Generate the PDF
  return html2pdf().from(element).set(opt).outputPdf('blob').then((pdfBlob) => {
    // Clean up the temporary element
    document.body.removeChild(element);
    return pdfBlob;
  });
};

export const generatePdfReportAndDownload = (content, filename, options = {}) => {
  const element = document.createElement('div');
  element.innerHTML = content;

  const opt = {
    margin: [20, 20, 20, 20], // Set margin for [top, left, bottom, right] in px
    filename: filename, // Use the provided filename
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
    jsPDF: { unit: 'px', format: [2400, 1600], orientation: 'landscape' }, // Set larger format and landscape orientation
    ...options,
  };

  // Append the element to the body temporarily
  document.body.appendChild(element);

  // Generate the PDF and trigger the download
  return html2pdf().from(element).set(opt).outputPdf('blob').then((pdfBlob) => {
    // Clean up the temporary element
    document.body.removeChild(element);

    // Create a download link and trigger the download
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(pdfBlob);
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    
    // Clean up the download link after triggering the download
    document.body.removeChild(downloadLink);
    
    // Revoke the object URL after the download to free memory
    URL.revokeObjectURL(downloadLink.href);

    return pdfBlob;
  });
};

