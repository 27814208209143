import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faSearch, faTruck, faCheckCircle, faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Table, InputGroup, Form, Row, Col, ButtonGroup } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setApproveBillingCount } from '../actions/billingdocument';
import { defaultPageNumber } from '../utils/constants';
import { formatCurrency } from "../utils/formatter";
import PortalPagination from '../components/PortalPagination';
import './styles.scss'
import { showError } from '../components/Alerts';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import { setPortalPage } from '../actions/blanket';
import reportApi from '../services/report-service';
import { generatePdfReportAndDownload } from '../pdfGenerator';
import ApprovedBillingPreview from './approvedbillingpreview';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { addCompanyHeader } from '../excelreportgenerator';

const ApprovedBillings = () => {
    const dispatch = useDispatch();
    const defaultStartDate = moment().startOf('month').toDate();
    const defaultEndDate = moment().endOf('month').toDate();
    const { getApprovedBillingCount, getApprovedBillingList, getApprovedBillingAll } = reportApi;
    const totalRecords = useSelector((state) => state.billingDocumentState.approvedCount) || 0;
    const [approvedList, setApprovedList] = useState([]);
    const [selectedPage, setSelectedPage] = useState(defaultPageNumber);
    const loading = useSelector((state) => state.blanket.showAdminLoader);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(defaultStartDate);
    const [endDateFilter, setEndDateFilter] = useState(defaultEndDate);
    const [searchContainerNumber, setSearchContainerNumber] = useState('');
    const [resetTriggered, setResetTriggered] = useState(false);
    const [overallTotalCBM, setOverallTotalCBM] = useState(0);
    const [overallTotalBillableAmount, setOverallTotalBillableAmount] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [allReportList, setAllReportList] = useState([]);
    const [overallTotalCBMPreview, setOverallTotalCBMPreview] = useState(0);
    const [overallTotalBillableAmountPreview, setOverallTotalBillableAmountPreview] = useState(0);

    const handlePageEvent = (pageNumber) => {
      handleFetchApprovedBillings(pageNumber);
    };

    const calculateTotals = (list) => {
        let totalCBM = 0;
        let totalBillableAmount = 0;

        list.forEach(document => {
            totalCBM += parseFloat(document.sumOfCBM) || 0;
            totalBillableAmount += parseFloat(document.totalBillableAmount) || 0;
        });

        setOverallTotalCBM(totalCBM);
        setOverallTotalBillableAmount(totalBillableAmount);
    };

    const calculatePreviewTotals = (list) => {
        let totalCBM = 0;
        let totalBillableAmount = 0;

        list.forEach(document => {
            totalCBM += parseFloat(document.sumOfCBM) || 0;
            totalBillableAmount += parseFloat(document.totalBillableAmount) || 0;
        });

        setOverallTotalCBMPreview(totalCBM);
        setOverallTotalBillableAmountPreview(totalBillableAmount);
    };

    const handleReset = () => {
      setSearchTerm('');
      setStartDateFilter(null);
      setEndDateFilter(null);
      setSearchContainerNumber('');
      setResetTriggered(true); 
      setAllReportList([]);
      setOverallTotalCBMPreview(0);
      setOverallTotalBillableAmountPreview(0)
    };

    const onSearchChange = (e) => {
      setSearchTerm(e.target.value || '');
    };

    const onkeyPressSearch = (e) => {
      if (e.key === 'Enter') {
        handleFetchApprovedBillings();
      }
    };
  
    const onFilterContainerChange = (e) => {
      setSearchContainerNumber(e.target.value || '');
    };

    const handleSearchDocument = () => {
      if (startDateFilter && endDateFilter && moment(startDateFilter).isAfter(endDateFilter)) {
        showError("End date cannot be earlier than start date.")
        return;
      }

      handleFetchApprovedBillings(defaultPageNumber);
    }

    const handleFetchApprovedBillings = async (pageNumber = 1) => {
      setSelectedPage(pageNumber);

      try {
        const countRes = await getApprovedBillingCount(searchTerm, startDateFilter, endDateFilter, searchContainerNumber);

        dispatch(setApproveBillingCount(countRes.totalCount));

        const listRes = await getApprovedBillingList(pageNumber, searchTerm, startDateFilter, endDateFilter, searchContainerNumber);

        setApprovedList(listRes.results);

        calculateTotals(listRes.results);

      } catch (error) {
        console.error('Error while searching approved list:', error);
      }
    }

    const handleStartDateChange = (date) => {
      if (endDateFilter && date.isAfter(endDateFilter)) {
        showError("Start date cannot be later than end date.")
        return;
      }
      setStartDateFilter(date);
    };
  
    const handleEndDateChange = (date) => {
      if (startDateFilter && date.isBefore(startDateFilter)) {
        showError("End date cannot be earlier than start date.")
        return;
      }
      setEndDateFilter(date);
    };

    useEffect(() => {
        getApprovedBillingCount(searchTerm, startDateFilter, endDateFilter).then((res) => {
        dispatch(setApproveBillingCount(res.totalCount));
        getApprovedBillingList(1, searchTerm, startDateFilter, endDateFilter).then((res) => {
          setApprovedList(res.results);
          calculateTotals(res.results);
        }); 
      });
    }, [getApprovedBillingCount, dispatch, getApprovedBillingList, endDateFilter, searchTerm, startDateFilter]);

    useEffect(() => {
      handleFetchApprovedBillings(defaultPageNumber);
      setResetTriggered(false);
    }, [resetTriggered]);

    const TableRow = (document) => {
        const { displayName } = document.clientData;
        const { 
            sumOfCBM, 
            sumOfSensitiveCharge, 
            sumOfForklift, 
            sumOfInland, 
            sumOfGrossWeight,
            grossAmount,
            totalBillableAmount,
            containerNumber,
            loadingDate,
            isSentToCustomer
        } = document;

        let rate = 0;

        if(document.appliedRateData) rate = document.appliedRateData.rate;

        if (document.appliedClientSpecialRateData && Object.keys(document.appliedClientSpecialRateData).length !== 0) {
            const { fixRate, bracketRate } = document.appliedClientSpecialRateData;
            rate = parseFloat(fixRate) > 0 ? parseFloat(fixRate) : parseFloat(bracketRate);
        }

        return (
            <tr>
                <td>
                    <span className="fw-normal">{displayName || ''}</span>
                </td>
                <td>
                    <span className="fw-normal">{containerNumber || ''}</span>
                </td>
                <td>
                    <span className="fw-normal">{moment(loadingDate).format('MMM. DD, YYYY') || ''}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(sumOfCBM)) || 0.00}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(sumOfSensitiveCharge)) || 0.00}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(sumOfForklift)) || 0.00}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(sumOfInland)) || 0.00}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(sumOfGrossWeight)) || 0.00}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(rate)) || 0.00}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(grossAmount)) || 0.00}</span>
                </td>
                <td>
                    <span className="fw-normal">{formatCurrency(parseFloat(totalBillableAmount)) || 0.00}</span>
                </td>
                <td>
                    {isSentToCustomer && (
                        <h6 className="fw-normal text-gray">
                            <FontAwesomeIcon icon={faCheckCircle} className="icon icon-xs text-success w-20 me-1 mt-2" />
                        </h6>
                    )}
                </td>
            </tr>
        );
    };

    const handleDownloadReport = async () => {
        try {
            setDownloading(true);
            
            const listRes = await getApprovedBillingAll(searchTerm, startDateFilter, endDateFilter, searchContainerNumber);

            setAllReportList(listRes.results);

            calculatePreviewTotals(listRes.results);

            setTimeout(() => {
                handleDownloadPdf();
            }, 100);
        } catch (error) {
            console.error("Error while generating transactions per loading report:", error);
        }
    };

    const handleDownloadPdf = async () => {
            try {
                const formattedStartDate = startDateFilter ? moment(startDateFilter).format('YYYY-MM-DD') : 'start';
                const formattedEndDate = endDateFilter ? moment(endDateFilter).format('YYYY-MM-DD') : 'end';
                let fileName = `approved_billings_report_${formattedStartDate}_${formattedEndDate}.pdf`;
                let pdfContent = document.getElementById('element-to-print_approved_billings').innerHTML;
    
                await generatePdfReportAndDownload(pdfContent, fileName);
                setDownloading(false);
            } catch (error) {
                console.error('Error in downloading PDF:', error);
            }
        };

        const handleDownloadExcel = async () => {
        try {
            setDownloading(true);
    
            // Define start and end dates for the report
            const formattedStartDate = startDateFilter ? moment(startDateFilter).format('YYYY-MM-DD') : 'start';
            const formattedEndDate = endDateFilter ? moment(endDateFilter).format('YYYY-MM-DD') : 'end';
    
            // Fetch the report data
            const listResponse = await getApprovedBillingAll(searchTerm, startDateFilter, endDateFilter, searchContainerNumber);
            const responseData = listResponse?.results || [];
    
            // Create a new workbook and worksheet
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Approved Billings Report');
    
            // Add company header, title, and date range to the worksheet
            let title = 'Approved Billings Report';
            await addCompanyHeader(worksheet, title, formattedStartDate, formattedEndDate);
    
            worksheet.getRow(12).height = 20;
            worksheet.getRow(13).height = 20;
    
            // Add column headers for the list view
            const headerRow = worksheet.addRow([
                'Display Name',
                'Tracking Number',
                'Loading Date',
                'Sum of CBM',
                'Sum of Sensitive Charge',
                'Sum of Forklift',
                'Sum of Inland',
                'Sum of Gross Wt',
                'Rate',
                'Freight Amount',
                'Billable Amount',
                'Sent'
            ]);
    
            // Style the column headers
            headerRow.eachCell((cell) => {
                cell.font = { bold: true };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFEAEAEA' },
                };
                cell.alignment = { horizontal: 'center' };
            });
    
            // Set column widths for the list view
            worksheet.columns = [
                { key: 'displayName', width: 25 },
                { key: 'containerNumber', width: 20 },
                { key: 'loadingDate', width: 20 },
                { key: 'sumOfCBM', width: 15 },
                { key: 'sumOfSensitiveCharge', width: 20 },
                { key: 'sumOfForklift', width: 20 },
                { key: 'sumOfInland', width: 20 },
                { key: 'sumOfGrossWeight', width: 20 },
                { key: 'rate', width: 20 },
                { key: 'grossAmount', width: 20 },
                { key: 'totalBillableAmount', width: 20 },
                { key: 'isSentToCustomer', width: 15 }
            ];
    
            // Add data rows for the list view
            responseData.forEach((item) => {
                worksheet.addRow([
                    item.clientData?.displayName || '',
                    item.containerNumber || '',
                    moment(item.loadingDate).format('MMM. DD, YYYY') || '',
                    formatCurrency(parseFloat(item.sumOfCBM)) || 0.00,
                    formatCurrency(parseFloat(item.sumOfSensitiveCharge)) || 0.00,
                    formatCurrency(parseFloat(item.sumOfForklift)) || 0.00,
                    formatCurrency(parseFloat(item.sumOfInland)) || 0.00,
                    formatCurrency(parseFloat(item.sumOfGrossWeight)) || 0.00,
                    formatCurrency(parseFloat(item.appliedRateData?.rate)) || 0.00,
                    formatCurrency(parseFloat(item.grossAmount)) || 0.00,
                    formatCurrency(parseFloat(item.totalBillableAmount)) || 0.00,
                    item.isSentToCustomer ? 'Yes' : 'No'
                ]);
            });
    
            // Add overall totals for CBM and Billable Amount at the bottom
            const lastRow = responseData.length + 14;
            worksheet.getCell(`A${lastRow + 1}`).value = 'Overall Total CBM:';
            worksheet.getCell(`A${lastRow + 1}`).font = { bold: true, size: 16 };
            worksheet.getCell(`B${lastRow + 1}`).value = (overallTotalCBM || 0).toFixed(2);
            worksheet.getCell(`B${lastRow + 1}`).font = { bold: true, size: 16 };
    
            worksheet.getCell(`D${lastRow + 1}`).value = 'Overall Total Billable Amount:';
            worksheet.getCell(`D${lastRow + 1}`).font = { bold: true, size: 16 };
            worksheet.getCell(`E${lastRow + 1}`).value = (overallTotalBillableAmount || 0).toFixed(2);
            worksheet.getCell(`E${lastRow + 1}`).font = { bold: true, size: 16 };
    
            // Save the Excel file
            const excelFileName = `approved_billings_report_${formattedStartDate}_${formattedEndDate}.xlsx`;
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer]), excelFileName);
            });
    
            setDownloading(false);
        } catch (error) {
            console.log('Error while generating Excel report: ', error);
            setDownloading(false);
        }
    };        

     useEffect(() => {
            dispatch(setPortalPage('Approved Billings Report'));
    }, [dispatch]);

    return (
        <>
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="pt-0">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0 search-block-width-400">
                    <p className="mb-0">Search and filter approved billings</p>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0"></div>
                </div>
                <div className="table-settings mb-4">
                    <Row className="justify-content-between align-items-center">
                    <Col md={3}>
                        <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            onKeyPress={onkeyPressSearch}
                            value={searchTerm}
                            onChange={onSearchChange}
                            placeholder="Search"
                        />
                        </InputGroup>
                    </Col>
                    <Col md={3}>
                        <Datetime
                        timeFormat={false}
                        onChange={handleStartDateChange}
                        closeOnSelect={true}
                        renderInput={(props, openCalendar) => (
                            <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faCalendar} />
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                name="startDateFilter"
                                value={startDateFilter ? moment(startDateFilter).format('MM/DD/YYYY') : ''}
                                placeholder="Filter start date"
                                onFocus={openCalendar}
                                onChange={(e) => setStartDateFilter(e.target.value)}
                            />
                            </InputGroup>
                        )}
                        />
                    </Col>
                    <Col md={3}>
                        <Datetime
                        timeFormat={false}
                        onChange={handleEndDateChange}
                        closeOnSelect={true}
                        renderInput={(props, openCalendar) => (
                            <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faCalendar} />
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                name="endDateFilter"
                                value={endDateFilter ? moment(endDateFilter).format('MM/DD/YYYY') : ''}
                                placeholder="Filter end date"
                                onFocus={openCalendar}
                                onChange={(e) => setEndDateFilter(e.target.value)}
                                disabled={!startDateFilter}
                            />
                            </InputGroup>
                        )}
                        />
                    </Col>
                    <Col md={3}>
                        <InputGroup>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faTruck} />
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            onKeyPress={onkeyPressSearch}
                            value={searchContainerNumber}
                            onChange={onFilterContainerChange}
                            placeholder="Filter container number"
                        />
                        </InputGroup>
                    </Col>
                    </Row>
                    <Row className="justify-content-between align-items-center mt-3">
                    <Col md={12}>
                        <Button variant="primary" type="button" onClick={() => handleSearchDocument()}>
                        Search
                        </Button>
                        <Button className='ms-1' variant="secondary" type="button" onClick={() => handleReset()}>
                        Reset
                        </Button>
                        {approvedList.length > 0 && (
                            <ButtonGroup className="pull-right ms-2">
                                <Button variant="primary" size="sm" type="button" onClick={() => handleDownloadReport()}>
                                    <FontAwesomeIcon icon={faFilePdf} /> {`${downloading ? 'Downloading...' : 'Download PDF'}`}
                                </Button>
                                <Button variant="secondary" size="sm" type="button" onClick={() => handleDownloadExcel()}>
                                    <FontAwesomeIcon icon={faFileExcel} /> {`${downloading ? 'Downloading...' : 'Download Excel'}`}
                                </Button>
                            </ButtonGroup>
                        )}
                    </Col>
                    </Row>
                </div>
                <Table hover className="billing-table-list align-items-center mt-3">
                    <thead>
                        <tr>
                            <th className="border-bottom">Display Name</th>
                            <th className="border-bottom">Tracking Number</th>
                            <th className="border-bottom">Loading Date</th>
                            <th className="border-bottom">Sum of CBM</th>
                            <th className="border-bottom">Sum of Sensitive Charge</th>
                            <th className="border-bottom">Sum of Forklift</th>
                            <th className="border-bottom">Sum of Inland</th>
                            <th className="border-bottom">Sum of Gross Wt</th>
                            <th className="border-bottom">Rate</th>
                            <th className="border-bottom">Freight Amount</th>
                            <th className="border-bottom">Billable Amount</th>
                            <th className="border-bottom">Sent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {approvedList && approvedList.map((t, i) => <TableRow key={`approved-billing-${i}`} {...t} />)}
                        {approvedList &&approvedList.length === 0 && (
                            <tr className="no-record"><td colSpan={11}>* No records found *</td></tr>
                        )}
                    </tbody>
                </Table>
                {approvedList.length > 0 && (
                    <div className="mt-4">
                        <Row>
                            <Col md={6}>
                                <h4><strong>Overall Total CBM:</strong> {parseFloat(overallTotalCBM).toFixed(2)}</h4>
                            </Col>
                            <Col md={6}>
                                <h4><strong>Overall Total Billable Amount:</strong> {formatCurrency(parseFloat(overallTotalBillableAmount)) || 0.00}</h4>
                            </Col>
                        </Row>
                    </div>
                )}
                {!loading && (
                    <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={handlePageEvent} />
                )}
                </Card.Body>
            </Card>
            <div className="report-element-to-print" id="element-to-print_approved_billings">
                <ApprovedBillingPreview 
                    startDate={startDateFilter} 
                    endDate={endDateFilter} 
                    reportList={allReportList}
                    overallTotalCBM={overallTotalCBMPreview}
                    overallTotalBillableAmount={overallTotalBillableAmountPreview}
                />
            </div>
        </>
    )
}

export default ApprovedBillings;