import { requests } from './api-service';

const reportApi = {
    getTransactionsHandledByAgentCount: (agentId, status, startDate, endDate) => 
        requests.get('transactions-handled-by-sales-agent/count', setTransactionsHandledByAgentCountParams(agentId, status, startDate, endDate)),
    getTransactionsHandledByAgentList: (pageNumber, agentId, status, startDate, endDate) => 
        requests.get('transactions-handled-by-sales-agent', setTransactionsHandledByAgentParams(agentId, status, startDate, endDate, pageNumber)),
    getAllTransactionsHandledByAgentList: (agentId, status, startDate, endDate) => 
        requests.get('all-transactions-handled-by-sales-agent', setTransactionsHandledByAgentCountParams(agentId, status, startDate, endDate)),
    getCommissionCalculation: (startDate, endDate) => 
        requests.get('commission-calculation', setDateParams(startDate, endDate)),
    getCbmRateCounts: (startDate, endDate) => 
        requests.get('cbm-rate-counts', setDateParams(startDate, endDate)),
    getFclTransactions: (startDate, endDate, shipmentType) => 
        requests.get('fcl-transactions', setShipmentAndDatesParams(startDate, endDate, shipmentType)),
    getTransactionsPerLoadingCount: (containerNumber, startDate, endDate) => 
        requests.get('transactions-per-loading/count', setTransactionsPerLoadingCountParams(containerNumber, startDate, endDate)),
    getTransactionsPerLoadingList: (pageNumber, containerNumber, startDate, endDate) => 
        requests.get('transactions-per-loading/list', setTransactionsPerLoadingListParams(containerNumber, startDate, endDate, pageNumber)),
    getAllTransactionsPerLoadingList: (containerNumber, startDate, endDate) => 
        requests.get('all-transactions-per-loading/list', setTransactionsPerLoadingCountParams(containerNumber, startDate, endDate)),
    getContainerReportByLoadingDate: (startDate, endDate) => 
        requests.get('container-category-by-loading-date', setDateParams(startDate, endDate)),
    getClientPercentageByLoadingDate: (containerNumber, startDate, endDate) => 
        requests.get('client-percentage-by-loading-date', setTransactionsPerLoadingCountParams(containerNumber, startDate, endDate)),
    getApprovedBillingCount: (search, filterStartDate, filterEndDate, filterContainerNumber) => 
        requests.get('approved-billing/count', setBillingListCountParams(null, search, filterStartDate, filterEndDate, filterContainerNumber)),
    getApprovedBillingList: (pageNumber, search, filterStartDate, filterEndDate, filterContainerNumber) => 
        requests.get('approved-billing/list', setBillingtListParams(null, pageNumber, search, filterStartDate, filterEndDate, filterContainerNumber)),
    getApprovedBillingAll: (search, filterStartDate, filterEndDate, filterContainerNumber) => 
        requests.get('approved-billing/all', setBillingtAllParams(search, filterStartDate, filterEndDate, filterContainerNumber)),
};

export const setTransactionsHandledByAgentCountParams = (agentId, status, startDate, endDate) => {
    const params = new URLSearchParams();
    params.append('agentId', agentId.toString());
    params.append('status', status.toString());
    params.append('startDate', startDate.toString());
    if (endDate) params.append('endDate', endDate.toString());
    
    return params;
};

export const setTransactionsHandledByAgentParams = (agentId, status, startDate, endDate, pageNumber = 1) => {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
    const params = new URLSearchParams();
    params.append('agentId', agentId.toString());
    params.append('status', status.toString());
    params.append('startDate', startDate.toString());
    if (endDate) params.append('endDate', endDate.toString());
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', limit.toString());
    return params;
};

export const setDateParams = (startDate, endDate) => {
    const params = new URLSearchParams();
    params.append('startDate', startDate.toString());
    params.append('endDate', endDate.toString());
    
    return params;
};

export const setShipmentAndDatesParams = (startDate, endDate, shipmentType) => {
    const params = new URLSearchParams();
    params.append('startDate', startDate.toString());
    params.append('endDate', endDate.toString());
    params.append('shipmentType', shipmentType);

    return params;
};

export const setTransactionsPerLoadingCountParams = (containerNumber, startDate, endDate) => {
    const params = new URLSearchParams();
    params.append('containerNumber', containerNumber);
    params.append('startDate', startDate.toString());
    if (endDate) params.append('endDate', endDate.toString());
    
    return params;
};

export const setTransactionsPerLoadingListParams = (containerNumber, startDate, endDate, pageNumber = 1) => {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
    const params = new URLSearchParams();
    params.append('containerNumber', containerNumber);
    params.append('startDate', startDate.toString());
    if (endDate) params.append('endDate', endDate.toString());
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', limit.toString());
    return params;
};

export const setBillingListCountParams = (status, search, filterStartDate, filterEndDate, filterContainerNumber) => {
    const countParams = new URLSearchParams();
    
    if (status) countParams.append('status', status);
    if (search) countParams.append('search', search);
    if (filterStartDate) countParams.append('filterStartDate', filterStartDate);
    if (filterEndDate) countParams.append('filterEndDate', filterEndDate);
    if (filterContainerNumber) countParams.append('filterContainerNumber', filterContainerNumber);
    return countParams;
};

export const setBillingtListParams = (status, pageNumber = 1, search = '', filterStartDate = '', filterEndDate = '', filterContainerNumber = '') => {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', limit.toString());
    if (status) params.append('status', status);
    if (search) params.append('search', search);
    if (filterStartDate) params.append('filterStartDate', filterStartDate);
    if (filterEndDate) params.append('filterEndDate', filterEndDate);
    if (filterContainerNumber) params.append('filterContainerNumber', filterContainerNumber);
    return params;
};

export const setBillingtAllParams = (search = '', filterStartDate = '', filterEndDate = '', filterContainerNumber = '') => {
    const params = new URLSearchParams();
    if (search) params.append('search', search);
    if (filterStartDate) params.append('filterStartDate', filterStartDate);
    if (filterEndDate) params.append('filterEndDate', filterEndDate);
    if (filterContainerNumber) params.append('filterContainerNumber', filterContainerNumber);
    return params;
};

export default reportApi;